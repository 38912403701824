const acData = [
    {
        id : 1,
        date:'16032023',
        userEmail : "bilal@gmail.com",
        userName : "bilal",
        userDuty: "Present"
    },
    {
        id : 2,
        date:'19032023',
        userEmail : "bilal@gmail.com",
        userName : "bilal",
        userDuty: "Present"
    },
    {
        id : 3,
        date:'18032023',
        userEmail : "bilal@gmail.com",
        userName : "bilal",
        userDuty: "Present"
    },
    {
        id : 4,
        date:'17032023',
        userEmail : "bilal@gmail.com",
        userName : "bilal",
        userDuty: "Present"
    },
    {
        id : 5,
        date:'30032023',
        userEmail : "admin@gmail.com",
        userName : "admin",
        userDuty: "Present"
    }
    
]
export default acData