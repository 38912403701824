const UsersData = [
    {
        name:'admin',
        email : "admin@gmail.com",
        password: "admin",
        role: "admin"
    },
    {
        name:'bilal',
        email : "bilal@gmail.com",
        password: "123456",
        role: "manager"
    }
    
]
export default UsersData

